<template>
  <KTCodePreview v-bind:title="formTitle">
    <template v-slot:preview>
      <div>
        <b-form @submit="onSubmit">
          <b-form-group label="Nhập tên cho Form" label-for="input-name">
            <b-form-input
              v-model="form.name"
              size="sm"
              required
              :disabled="Boolean($route.params.uuid)"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label="Lựa chọn Campaign"
            label-for="input-1"
            description="Hãy lựa chọn một campaign mà form này sẽ được áp dụng"
          >
            <b-form-select
              v-model="form.campaignId"
              :options="campaignOptions"
              required
              size="sm"
            >
              <template #first>
                <b-form-select-option :value="null" disabled
                  >-- Hãy lựa chọn một campaign --
                </b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
          <h5>
            Các cửa hàng sẽ thực hiện chiến dịch trên:
          </h5>
          <b-row>
            <b-col sm="6">
              <b-form-group
                id="input-stores"
                label="Chọn các cửa hàng:"
                label-for="input-end-date"
              >
                <MultiselectOption
                  id="input-stores"
                  :model.sync="form.stores"
                  :options="filteredOptions"
                  :trackBy="'code'"
                  @selectAll="selectStoreHandler($event, true)"
                  @removeAll="selectStoreHandler($event, false)"
                  :placeholder="'cửa hàng'"
                  @searchChange="inputChangeHandler($event)"
                  :disabled="disableStoresSelection"
                />
                <b-form-checkbox
                  v-model="disableStoresSelection"
                  @change="toggleAllStores($event)"
                  >Chọn tất cả các cửa hàng
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group v-if="form.stores && form.stores.length > 0">
                <b-table
                  sticky-header
                  style="height: 240px"
                  :items="form.stores"
                  :fields="fields"
                  class="mb-0 px-0 border rounded"
                ></b-table>
              </b-form-group>
            </b-col>
          </b-row>

          <section>
            <h5>
              Tạo POSM:
            </h5>
            <section class="mb-8">
              <h6>Bên Ngoài</h6>
              <b-row class="mb-4">
                <b-col>
                  <b-button
                    v-if="!$route.params.uuid"
                    variant="primary"
                    @click="addExteriorPOSMCategory"
                    >Thêm danh mục bên ngoài
                  </b-button>
                </b-col>
                <b-col class="text-right">
                  <b-button
                    v-if="!$route.params.uuid"
                    variant="danger"
                    @click="removeExteriorPOSMCategory"
                    >Xoá danh mục bên ngoài
                  </b-button>
                </b-col>
              </b-row>
              <b-table
                :items="form.exteriorCategories"
                :fields="posmCategoryFields"
                :current-page="currentExteriorPosmPage"
                :per-page="perPage"
                class="border rounded"
                ref="exteriorPosmTable"
              >
                <template #cell(name)="row" style="width: 20%;">
                  <div>
                    <b-form-group>
                      <template v-slot:label>
                        {{
                          `Tên hạng mục thứ ${Number(
                            form.exteriorCategories.indexOf(row.item) + 1,
                          )}`
                        }}
                        <span class="text-danger">*</span>
                      </template>
                      <b-form-input
                        v-model="row.item.name"
                        size="sm"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                </template>
                <template #cell(items)="row" style="width: 80%">
                  <b-row
                    v-for="(categoryItem,
                    categoryItemIndex) of row.item.items.slice(
                      (row.item.itemsPage - 1) * perPage,
                      (row.item.itemsPage - 1) * perPage + perPage,
                    )"
                    :key="`row-${row.index}-item-${categoryItemIndex}`"
                  >
                    <b-col sm="10">
                      <b-row class="align-content-center">
                        <b-col sm="6">
                          <b-form-group>
                            <template v-slot:label>
                              {{
                                `Tên vị trí hạng mục thứ ${Number(
                                  row.item.items.indexOf(categoryItem) + 1,
                                )}`
                              }}
                              <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              size="sm"
                              v-model="categoryItem.name"
                            ></b-form-input>
                          </b-form-group>
                        </b-col>
                        <b-col sm="6">
                          <b-form-group
                            :label="
                              'Kích thước vị trí hạng mục thứ ' +
                                Number(row.item.items.indexOf(categoryItem) + 1)
                            "
                          >
                            <b-form-input
                              v-model="categoryItem.size"
                              size="sm"
                            ></b-form-input>
                          </b-form-group>
                        </b-col>
                        <b-col sm="6">
                          <b-form-group>
                            <template v-slot:label>
                              {{
                                `Độ ưu tiên vị trí hạng mục thứ ${Number(
                                  row.item.items.indexOf(categoryItem) + 1,
                                )}`
                              }}
                              <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                              v-model="categoryItem.priority"
                              :options="priorityOptions"
                              size="sm"
                            >
                              <template #first>
                                <b-form-select-option :value="null" disabled
                                  >-- Hãy lựa chọn độ ưu tiên --
                                </b-form-select-option>
                              </template>
                            </b-form-select>
                          </b-form-group>
                        </b-col>
                        <b-col sm="6">
                          <b-form-group label="Dùng để trưng bày cho hãng: ">
                            <b-form-select
                              v-model="categoryItem.brandId"
                              :options="brandOptions"
                              size="sm"
                            >
                              <template #first>
                                <b-form-select-option :value="-1"
                                  >-- Áp dụng chung --
                                </b-form-select-option>
                              </template>
                            </b-form-select>
                          </b-form-group>
                        </b-col>
                        <b-col sm="6">
                          <b-form-group>
                            <template v-slot:label>
                              {{
                                `Note vị trí hạng mục thứ ${Number(
                                  row.item.items.indexOf(categoryItem) + 1,
                                )}`
                              }}
                            </template>
                            <b-form-input
                              v-model="categoryItem.position"
                              size="sm"
                            >
                            </b-form-input>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col cols="1">
                      <b-button
                        variant="danger"
                        v-if="!$route.params.uuid"
                        @click="removePOSMCategoryItem(row.item.items, row)"
                        size="sm"
                      >
                        Xóa
                      </b-button>
                    </b-col>
                    <b-col cols="12">
                      <hr />
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col cols="12">
                      <b-button
                        class="btn btn-block"
                        variant-="outline-primary"
                        v-if="!$route.params.uuid"
                        @click="addPOSMCategoryItem(row.item.items, row.items)"
                        size="sm"
                      >
                        Thêm
                      </b-button>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12" class="my-4">
                      <b-pagination
                        class="align-self-end"
                        :value="row.item.itemsPage"
                        @input="updateExteriorPage(row.item, $event)"
                        :total-rows="row.item.items.length"
                        :per-page="perPage"
                        align="right"
                        aria-controls="my-table-exterior"
                      ></b-pagination>
                    </b-col>
                  </b-row>
                </template>
              </b-table>
              <b-pagination
                v-model="currentExteriorPosmPage"
                :total-rows="form.exteriorCategories.length"
                :per-page="perPage"
                aria-controls="my-table-exterior"
                align="center"
              ></b-pagination>
            </section>
            <section class="mb-8">
              <h6>Bên Trong</h6>
              <b-row class="mb-4">
                <b-col>
                  <b-button
                    v-if="!$route.params.uuid"
                    variant="primary"
                    @click="addInteriorPOSMCategory"
                    size="sm"
                    >Thêm danh mục bên trong
                  </b-button>
                </b-col>
                <b-col class="text-right">
                  <b-button
                    v-if="!$route.params.uuid"
                    variant="danger"
                    @click="removeInteriorPOSMCategory"
                    size="sm"
                    >Xoá danh mục bên ngoài
                  </b-button>
                </b-col>
              </b-row>
              <b-table
                :items="form.interiorCategories"
                :fields="posmCategoryFields"
                :current-page="currentInteriorPosmPage"
                :per-page="perPage"
                class="border rounded"
                ref="interiorPosmTable"
              >
                <template #cell(name)="row" style="width: 20%;">
                  <div>
                    <b-form-group>
                      <template v-slot:label>
                        {{
                          'Tên hạng mục thứ ' +
                            Number(
                              form.interiorCategories.indexOf(row.item) + 1,
                            )
                        }}
                        <span class="text-danger">*</span>
                      </template>
                      <b-form-input
                        v-model="row.item.name"
                        size="sm"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                </template>
                <template #cell(items)="row" style="width: 80%">
                  <b-row
                    v-for="(categoryItem,
                    categoryItemIndex) of row.item.items.slice(
                      (row.item.itemsPage - 1) * perPage,
                      (row.item.itemsPage - 1) * perPage + perPage,
                    )"
                    :key="`row-${row.index}-item-${categoryItemIndex}`"
                  >
                    <b-col sm="10">
                      <b-row class="align-content-center">
                        <b-col sm="6">
                          <b-form-group>
                            <template v-slot:label>
                              {{
                                'Tên vị trí hạng mục thứ ' +
                                  Number(
                                    row.item.items.indexOf(categoryItem) + 1,
                                  )
                              }}
                              <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                              v-model="categoryItem.name"
                              size="sm"
                            ></b-form-input>
                          </b-form-group>
                        </b-col>
                        <b-col sm="6">
                          <b-form-group
                            :label="
                              'Kích thước vị trí hạng mục thứ ' +
                                Number(row.item.items.indexOf(categoryItem) + 1)
                            "
                          >
                            <b-form-input
                              v-model="categoryItem.size"
                              size="sm"
                            ></b-form-input>
                          </b-form-group>
                        </b-col>
                        <b-col sm="6">
                          <b-form-group>
                            <template v-slot:label>
                              {{
                                'Độ ưu tiên vị trí hạng mục thứ ' +
                                  Number(
                                    row.item.items.indexOf(categoryItem) + 1,
                                  )
                              }}
                              <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                              v-model="categoryItem.priority"
                              :options="priorityOptions"
                              size="sm"
                            >
                              <template #first>
                                <b-form-select-option :value="null" disabled
                                  >-- Hãy lựa chọn độ ưu tiên --
                                </b-form-select-option>
                              </template>
                            </b-form-select>
                          </b-form-group>
                        </b-col>
                        <b-col sm="6">
                          <b-form-group label="Dùng để trưng bày cho hãng: ">
                            <b-form-select
                              v-model="categoryItem.brandId"
                              :options="brandOptions"
                              size="sm"
                            >
                              <template #first>
                                <b-form-select-option :value="-1"
                                  >-- Áp dụng chung --
                                </b-form-select-option>
                              </template>
                            </b-form-select>
                          </b-form-group>
                        </b-col>
                        <b-col sm="6">
                          <b-form-group>
                            <template v-slot:label>
                              {{
                                'Note vị trí hạng mục thứ ' +
                                  Number(
                                    row.item.items.indexOf(categoryItem) + 1,
                                  )
                              }}
                            </template>
                            <b-form-input
                              v-model="categoryItem.position"
                              size="sm"
                            >
                            </b-form-input>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col cols="1">
                      <b-button
                        variant="danger"
                        v-if="!$route.params.uuid"
                        @click="removePOSMCategoryItem(row.item.items, row)"
                        size="sm"
                      >
                        Xóa
                      </b-button>
                    </b-col>
                    <b-col cols="12">
                      <hr />
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col cols="12">
                      <b-button
                        class="btn btn-block"
                        variant-="outline-primary"
                        v-if="!$route.params.uuid"
                        @click="addPOSMCategoryItem(row.item.items, row.items)"
                        size="sm"
                      >
                        Thêm
                      </b-button>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12" class="my-4">
                      <b-pagination
                        class="align-self-end"
                        @input="updateInteriorPage(row.item, $event)"
                        :value="row.item.itemsPage"
                        :total-rows="row.item.items.length"
                        :per-page="perPage"
                        align="right"
                        aria-controls="my-table-interior"
                      ></b-pagination>
                    </b-col>
                  </b-row>
                </template>
              </b-table>
              <b-pagination
                v-model="currentInteriorPosmPage"
                :total-rows="form.interiorCategories.length"
                :per-page="perPage"
                aria-controls="my-table-interior"
                align="center"
              ></b-pagination>
            </section>
          </section>

          <b-button type="submit" variant="primary" size="sm">Lưu</b-button>
        </b-form>
      </div>
    </template>
  </KTCodePreview>
</template>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import ApiService from '@/core/services/api.service';
import { cmdUrl } from '@/utils/apiUrl';
import { formatDate, getRemainingDays } from '@/utils/date';
import {
  APPLY_TO_ALL_BRANDS,
  DATETIME_FORMAT,
  SUCCESS_RESPONSE_STATUS,
} from '@/utils/constants';
import {
  makeToastFaile,
  makeToastSuccess,
  removeAccents,
} from '@/utils/common';
import SelectOption from '@/view/pages/posm/models/SelectOption';
import MultiselectOption from '@/view/base/multiselect/MultiselectOption.vue';
import { assign, cloneDeep, find, map } from 'lodash';
import ENUM from '@/utils/enum';
import CreatePOSMForm from '@/view/pages/posm/models/CreatePOSMForm';
import CreatePOSMCategoryItem from '@/view/pages/posm/models/CreatePOSMCategoryItem';
import CreatePOSMCategory from '@/view/pages/posm/models/CreatePOSMCategory';
import Store from '@/view/pages/posm/models/Store';
import moment from 'moment';

export default {
  components: {
    MultiselectOption,
    KTCodePreview,
  },
  data() {
    return {
      fetchedForm: false,
      form: new CreatePOSMForm({
        exteriorCategories: [
          new CreatePOSMCategory({
            inputType: ENUM.POSM_INPUT_TYPE.IMAGE,
            items: [
              new CreatePOSMCategoryItem({ brandId: APPLY_TO_ALL_BRANDS }),
            ],
          }),
        ],
        interiorCategories: [
          new CreatePOSMCategory({
            inputType: ENUM.POSM_INPUT_TYPE.IMAGE,
            items: [
              new CreatePOSMCategoryItem({ brandId: APPLY_TO_ALL_BRANDS }),
            ],
          }),
        ],
      }),

      campaignOptions: [],
      filteredOptions: [],
      storeOptions: [],

      fields: [
        { key: 'code', label: 'Mã cửa hàng' },
        { key: 'name', label: 'Tên cửa hàng' },
      ],
      posmCategoryFields: [
        { key: 'name', label: '' },
        { key: 'items', label: 'Vị trí hạng mục' },
      ],
      posmFields: [
        { key: 'name', label: 'Tên hạng mục' },
        { key: 'size', label: 'Kích thước' },
        { key: 'position', label: 'Vị trí' },
        { key: 'priority', label: 'Độ ưu tiên' },
        { key: 'actions' },
      ],
      priorityOptions: [
        new SelectOption('Thấp', 1),
        new SelectOption('Trung bình', 2),
        new SelectOption('Cao', 3),
      ],
      brandOptions: [],
      filteredBrandOptions: [],
      defaultPOSMCategory: new CreatePOSMCategory({
        inputType: ENUM.POSM_INPUT_TYPE.IMAGE,
        items: [new CreatePOSMCategoryItem({ brandId: APPLY_TO_ALL_BRANDS })],
      }),

      currentInteriorPosmPage: 1,
      currentExteriorPosmPage: 1,
      perPage: 2,

      disableStoresSelection: false,
    };
  },
  methods: {
    fetchCampaigns(params) {
      ApiService.query(`${cmdUrl.Campaign.root}/create-posm-form`, {
        params: params ? params : { ...this.apiParams },
      })
        .then(({ data }) => {
          this.campaignOptions = data.data.map((campaign) => {
            const now = moment().format(DATETIME_FORMAT);
            const formattedStartDate = formatDate(
              campaign.startDate,
              DATETIME_FORMAT,
            );
            const formattedEndDate = formatDate(
              campaign.endDate,
              DATETIME_FORMAT,
            );

            return new SelectOption(
              `${
                campaign.name
              }. Từ: ${formattedStartDate} - Đến: ${formattedEndDate} (Còn: ${getRemainingDays(
                now,
                formattedEndDate,
              )} ngày)`,
              campaign.id,
            );
          });
        })
        .catch((err) => {
          makeToastFaile(err.message);
        });
    },
    selectStoreHandler(option, checked) {
      let index = this.storeOptions.findIndex(
        (item) => item.name === option.name,
      );
      this.storeOptions[index].checked = checked;
      this.filteredOptions = cloneDeep(this.storeOptions);
    },
    fetchStore: async function() {
      this.optionsStore = [];
      ApiService.get(`/stores`).then((response) => {
        const stores = response.data.data;
        stores.map((element) => {
          let option = {
            code: element.id,
            name: element.shortName,
            checked: false,
          };
          this.storeOptions.push(option);
        });
        this.filteredOptions = cloneDeep(this.storeOptions);
      });
    },
    inputChangeHandler(textInput = '') {
      this.searchStore(textInput);
    },
    searchStore(textInput) {
      let options = cloneDeep(this.storeOptions);
      if (!textInput || !textInput.trim().length) {
        this.filteredOptions = map(options, (obj) => {
          return assign(obj, find(this.filteredOptions, { code: obj.code }));
        });
        return;
      }

      options = map(options, (obj) => {
        return assign(obj, find(this.filteredOptions, { code: obj.code }));
      });

      this.filteredOptions = this.filterOptionsBy(
        options,
        textInput,
        'name',
        10,
      );
    },
    fetchStoreForOption() {
      ApiService.get(`/stores/getStores`).then((response) => {
        const stores = response.data.data;
        stores.map((element) => {
          let option = {
            code: element.id,
            name: element.shortName,
            checked: false,
          };
          this.storeOptions.push(option);
        });
        this.filteredOptions = cloneDeep(this.storeOptions);
      });
    },
    filterOptionsBy(items, textInput, prop, limit) {
      return cloneDeep(items)
        .filter((item) => {
          if (item) {
            const nameWTUnicode = removeAccents(item[prop] || '');
            const nameInputWTUnicode = removeAccents(textInput);
            const index = nameWTUnicode
              .toLowerCase()
              .indexOf(nameInputWTUnicode.toLowerCase());

            if (index > -1) {
              return true;
            }
          }
          return false;
        })
        .slice(0, limit);
    },
    toggleAllStores() {
      this.form.stores = [];
      this.storeOptions = this.storeOptions.map((store) => {
        store.checked = false;
        return store;
      });
      this.filteredOptions = cloneDeep(this.storeOptions);
    },
    fetchBrands() {
      const paramQuery = {
        params: {
          param: {
            page: 1,
            pageSize: 1000,
            name: '',
          },
        },
      };
      ApiService.query(`/brands`, paramQuery)
        .then(({ data }) => {
          this.brandOptions = data.brands.map(
            (brand) => new SelectOption(brand.name, brand.id),
          );
        })
        .catch((err) => {
          makeToastFaile(err.message);
        });
    },
    removePOSMCategoryItem(arr, data) {
      if (arr.length > 1) {
        if (this.$route.params.uuid) {
          if (!data.hasOwnProperty('deletedCategoryIds')) {
            data.deletedCategoryIds = [];
          }
          data.deletedCategoryIds.push(arr.pop());
          return;
        }
        arr.pop();
      }
    },
    addPOSMCategoryItem(arr) {
      arr.push(
        Object.assign({},
          new CreatePOSMCategoryItem({ brandId: APPLY_TO_ALL_BRANDS }),
      ));
    },
    addExteriorPOSMCategory() {
      this.form.exteriorCategories.push(
        Object.assign(
          {},
          new CreatePOSMCategory({
            inputType: ENUM.POSM_INPUT_TYPE.IMAGE,
            items: [
              new CreatePOSMCategoryItem({ brandId: APPLY_TO_ALL_BRANDS }),
            ],
          }),
        ),
      );
    },
    removeExteriorPOSMCategory() {
      if (this.form.exteriorCategories.length > 1)
        this.form.exteriorCategories.pop();
    },
    addInteriorPOSMCategory() {
      this.form.interiorCategories.push(
        Object.assign(
          {},
          new CreatePOSMCategory({
            inputType: ENUM.POSM_INPUT_TYPE.IMAGE,
            items: [
              new CreatePOSMCategoryItem({ brandId: APPLY_TO_ALL_BRANDS }),
            ],
          }),
        ),
      );
    },
    removeInteriorPOSMCategory() {
      if (this.form.interiorCategories.length > 1)
        this.form.interiorCategories.pop();
    },
    fetchPOSMForm() {
      ApiService.get(`${cmdUrl.POSMForm.root}/${this.$route.params.uuid}`)
        .then(({ data }) => {
          if (data.status === SUCCESS_RESPONSE_STATUS) {
            const {
              name,
              campaignId,
              stores,
              exteriorCategories,
              interiorCategories,
            } = data.data;
            this.form.name = name;
            this.form.campaignId = campaignId;
            this.form.stores = stores.map((store) => {
              const parsedStore = new Store(store.id, store.shortName, true);
              let index = this.storeOptions.findIndex(
                (item) => item.name === parsedStore.name,
              );
              this.storeOptions[index].checked = true;
              return parsedStore;
            });
            this.filteredOptions = cloneDeep(this.storeOptions);
            this.form.exteriorCategories = cloneDeep(exteriorCategories);
            this.form.exteriorCategories.forEach((category, index) => {
              this.form.exteriorCategories[index].itemsPage = 1;
              this.form.exteriorCategories[index].id = category.categoryId;
            });
            this.form.interiorCategories = cloneDeep(interiorCategories);
            this.form.interiorCategories.forEach((category, index) => {
              this.form.interiorCategories[index].itemsPage = 1;
              this.form.interiorCategories[index].id = category.categoryId;
            });
            if (this.form.stores.length === 0) {
              this.disableStoresSelection = true;
            }
          }
        })
        .catch((err) => {
          console.log(err);
          makeToastFaile(err.message);
        });
    },
    onSubmit(evt) {
      evt.preventDefault();
      if (!this.$route.params.uuid) {
        ApiService.post('/posm-forms', this.form)
          .then(({ data }) => {
            if (data.status === SUCCESS_RESPONSE_STATUS) {
              makeToastSuccess('Tạo mới thành công');
              this.$router.push({ name: 'posm-form-overview' });
            }
          })
          .catch((err) => {
            makeToastFaile(err.message);
          });
      } else {
        ApiService.put(
          `${cmdUrl.POSMForm.root}/${this.$route.params.uuid}`,
          this.form,
        )
          .then(({ data }) => {
            if (data.status === SUCCESS_RESPONSE_STATUS) {
              makeToastSuccess('Cập nhật form thành công');
              this.$router.push({ name: 'posm-form-overview' });
            }
          })
          .catch((err) => {
            makeToastFaile(err.message);
          });
      }
    },
    updateExteriorPage(item, $event) {
      this.$set(item, 'itemsPage', $event);
      this.$refs.exteriorPosmTable.refresh();
    },
    updateInteriorPage(item, $event) {
      this.$set(item, 'itemsPage', $event);
      this.$refs.interiorPosmTable.refresh();
    },
  },

  created() {
    this.fetchCampaigns();
    this.fetchStoreForOption();
    this.fetchBrands();
  },
  mounted() {
    if (this.$route.params.uuid) {
      this.fetchPOSMForm();
      this.fetchedForm = true;
    }
  },
  computed: {
    formTitle() {
      return this.$route.params.uuid
        ? 'Cập nhật Form POSM'
        : 'Tạo mới Form POSM';
    },
  },
  watch: {
    'form.exteriorCategories': {
      deep: true,
      handler() {
        if (this.fetchedForm) {
          return;
        }
      },
    },
    'form.interiorCategories': {
      deep: true,
      handler() {
        if (this.fetchedForm) {
          return;
        }
      },
    },
  },
};
</script>
